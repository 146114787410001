import LayoutA6 from "../../components/layout-a6"
import React from "react"
import  Login from '../../components/login'

export default class A6Login extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA6/profile/enrollInfoList"
    return(
      <LayoutA6 >
        <Login color={color} src={src} />
      </LayoutA6>
    )
  }
}